<template>
	<div class="prodetail">
		<div class="content">
			<!--
            	宽高比5：4
            -->
			<div class="swipebox" v-show="ImageList.length>0">
				<div class="swipebar">
					<div class="swipeitem" v-for="url of ImageList">
						<img :src="url" style="width: 100%; height: 100%;" />
					</div>

				</div>
				<div style="position: absolute; bottom: 10px; width: 100%; text-align: center; color: #CCCCCC;">
					<div :class="['dot',n==swipeIdx+1?'dotckd':'']" v-for="n in ImageList.length"></div>

				</div>
			</div>
			<div class="proInfo">
				<div class="proName">{{prdDetail.title}}</div>
				<div class="proPrice">
					<div class="price_left">￥{{prdDetail.price |twobit}}</div>
					<div class="price_right">商品原价：<span style="text-decoration: line-through;">￥{{prdDetail.marketprice |twobit}}</span></div>
				</div>
				<div class="tradeInfo">
					<div class="trade_left">已售出：{{parseInt(prdDetail.sealnum)+ parseInt( prdDetail.visualseal)}}</div>
					<div class="trade_right">浏览量：{{prdDetail.scannum}}</div>
				</div>
			</div>
			<div class="probuyCount">
				<div class="probuy_left">购买数量</div>
				<div class="probuy_right">
					买一送一
					<div v-if="false">
						<button type="button" class="btn" @click="buyCount<=1?buyCount=1:buyCount--">-</button>
						<input type="text" v-model="buyCount" onfocus="this.blur()">
						<button type="button" class="btn1" @click="buyCount++">+</button>
					</div>
				</div>
			</div>
			<div class="probuyCount">
				<div :class="['viewtype',viewtype==0?'viewckd':'']" @click="viewtype=0">详情</div>
				<div :class="['viewtype',viewtype==1?'viewckd':'']" @click="viewtype=1">评价</div>
			</div>
			<!--详情或者评价-->
			<div id="prodetail" v-show="viewtype==0" style="padding: 10px; background:#fff; margin-top:10px;word-break: break-all;" v-html="prdDetail.goodinfo">

			</div>
			<div v-show="viewtype==1" style="padding: 10px; background:#fff; margin-top:10px;word-break: break-all;">
				<div v-for="item of commentList" style=" padding: 10px;border-bottom: 1px solid #eee;">
					<div style="height: 50px; width: 50px; float: left;">
						<img :src="item.headimgurl" style="width: 100%; height: 100%;border-radius: 4px;" />
					</div>
					<div style="min-height: 50px; margin-left: 70px;  padding-bottom: 10px;">
						<div style="padding-top: 6px;">
							{{item.nickname}}
						</div>
						<div style="padding-top: 5px;color: #000;">
							{{item.comments}}
						</div>
					</div>
				</div>
			</div>
			<div style="height: 80px;"></div>
		</div>
		<div class="footer">
			<div class="kf flexcenter" @click="GoKefu">
				<img src="../../public/static/images/custom1.png" style="width: 70%;width: 70%;" />
			</div>
			<div class="addcart" @click="addCart(prdDetail.id)">加入购物车</div>
			<div class="buy" @click="buy">立即购买</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				prdDetail: '', //商品详情
				buyCount: 1,
				id: 0,
				swipeCount: 0,
				swipeIdx: 0,
				ImageList: [],
				viewtype: 0,
				commentList: []

			}
		},
		created() {
			this.id = 29;
			this.getproDetail(); //商品详情

		},
		mounted() {
			//浏览一次
			this.$store.dispatch("GET", {
				url: "/api/wx/?action=view&id=" + this.id
			}).then(result => {

			})

			this.$nextTick(function() {
				$(window).scrollTop(0)
			})
		},
		methods: {
			GoKefu() {
				this.$router.push("/message")
			},
			InitSwipe() {
				this.swipeCount = this.ImageList.length;
				if(this.ImageList.length == 1) {
					return
				}
				var that = this;
				this.$nextTick(function() {

					$(".swipebar").css("width", that.swipeCount * 100 + "%")
					$(".swipeitem").css("width", 100 / that.swipeCount + "%")

					var tp = {
						sx: 0,
						tx: 0,
						dist: 0,
						width: MesPlus.wwidth * that.swipeCount,
						paging: false
					}

					$(".swipebox").on("touchstart", function(e) {
						e.stopPropagation();
						e.preventDefault();

						tp.sx = e.targetTouches[0].pageX
					})
					$(".swipebox").on("touchmove", function(e) {
						e.stopPropagation();
						e.preventDefault();
						if(!tp.paging) {
							tp.dist = e.targetTouches[0].pageX - tp.sx;
							if(tp.dist > 0) { //上一页，正

								if(that.swipeIdx > 0) {
									$(".swipebar").animate({
										translate3d: (tp.dist - MesPlus.wwidth * that.swipeIdx) + "px,0,0"
									}, 0, "ease-out")
								}

							} else {
								if(that.swipeIdx < that.swipeCount - 1) {
									$(".swipebar").animate({
										translate3d: (tp.dist - MesPlus.wwidth * that.swipeIdx) + "px,0,0"
									}, 0, "ease-out")
								}
								//-(100/that.swipeCount)+ "%,0,0"
							}

						}

					})
					$(".swipebox").on("touchend", function(e) {
						tp.paging = true;
						if(Math.abs(tp.dist) >= MesPlus.wwidth / 3) {
							if(tp.dist < 0) {
								if(that.swipeIdx < that.swipeCount - 1) {
									$(".swipebar").animate({
										opacity: 1
									}, 10, "linear", function() {
										$(".swipebar").animate({
											translate3d: (-MesPlus.wwidth * (that.swipeIdx + 1)) + "px,0,0"
										}, 300, "ease-out", function() {
											that.swipeIdx++;
											tp.paging = false
										})
									})
								}

							} else {
								if(that.swipeIdx > 0) {
									$(".swipebar").animate({
										opacity: 1
									}, 10, "linear", function() {
										$(".swipebar").animate({
											translate3d: (-MesPlus.wwidth * (that.swipeIdx - 1)) + "px,0,0"
										}, 300, "ease-out", function() {
											that.swipeIdx--;
											tp.paging = false
										})
									})
								}

							}
						} else {
							$(".swipebar").animate({
								opacity: 1
							}, 10, "linear", function() {
								$(".swipebar").animate({
									translate3d: (-MesPlus.wwidth * (that.swipeIdx)) + "px,0,0"
								}, 300, "ease-out", function() {

									tp.paging = false
								})
							})

						}

						tp.sx = 0;
						tp.tx = 0;
						tp.dist = 0;
					})
				})

			},
			//获取商品详情
			getproDetail() {
				var self = this;
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=product&id=" + this.id
				}).then(result => {
					self.prdDetail = result.data.data.rows[0];

					self.prdDetail.goodinfo = decodeURIComponent(self.prdDetail.goodinfo)
					console.log("prdDetail", self.prdDetail);
					this.ImageList = self.prdDetail.images.split(",")
					console.log("ImageList", this.ImageList)
					this.InitSwipe();
					this.$nextTick(function() {
						$("img", "#prodetail").each(function(idx, ele) {
							$(ele).css("width", "100%").css("height", "auto").css("vertical-align", "top")
						})
					})
					this.getproComment();

					//分享
					this.$store.dispatch("SHARE", {
						query: {
							shareurl:window.location.href.split('#')[0]
						},
						opt:{
							title:self.prdDetail.title,
							desc:"通过该链接购买享受买一送一优惠",
							link:window.location.href.split("#")[0]+"#/give",
							imgUrl:self.prdDetail.images,
							success:function(){}
						}
					}).then(result => {})

				})
			},
			getproComment() {
				var self = this;
				this.$store.dispatch("GET", {
					url: "/api/shop/?action=comment&goodid=" + this.id
				}).then(result => {
					this.commentList = result.data.data.rows.rows
					console.log(this.commentList)
				})
			},
			//添加购物车
			addCart(id) {
				var self = this;
				this.$store.dispatch("POST", {
					url: "/api/wx/?action=addtocart",
					query: {
						id: id,
						num: self.buyCount
					}
				}).then(result => {
					if(result.data.code == 200) {
						$Toast("添加成功");
					} else {
						$Toast(result.data.msg);
					}
				})
			},
			//立即购买
			buy() {
				sessionStorage.setItem("goodid", this.id);
				sessionStorage.setItem("buycount", this.buyCount);
				this.$router.push("/twobuy")
			}
		}
	}
</script>

<style scoped="scoped">
	.swipebox {
		width: 100%;
		height: 80vw;
		position: relative;
		overflow: hidden;
	}
	
	.swipebar {
		height: 100%;
	}
	
	.swipeitem {
		height: 100%;
		float: left;
	}
	
	.dot {
		width: 6px;
		height: 6px;
		border: 1px solid #ccc;
		border-radius: 50%;
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;
	}
	
	.dotckd {
		background: #ccc;
	}
	
	.prodetail {
		height: 100%;
		width: 100%;
	}
	
	.content {
		margin-bottom: 50px;
	}
	
	.proimg {
		height: 277px;
		background: #FFFFFF;
	}
	
	.proInfo {
		height: 115px;
		background: #f6fafe;
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.proName {
		margin-top: 5px;
		padding-top: 5px;
		font-size: 18px;
		color: #000000;
	}
	
	.proPrice {
		display: flex;
		margin-top: 15px;
	}
	
	.price_left {
		font-size: 24px;
		color: #d92b2b;
		flex: 1;
	}
	
	.price_right {
		font-size: 15px;
		color: #7b7b7b;
		margin-top: 8px;
	}
	
	.tradeInfo {
		margin-top: 10px;
		display: flex;
		font-size: 12px;
		color: #7b7b7b;
		padding-bottom: 10px;
	}
	
	.trade_right {
		margin-left: 30px;
	}
	
	.probuyCount {
		margin-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		height: 58px;
		line-height: 58px;
		background: #f6fafe;
		display: flex;
	}
	
	.probuy_left {
		font-size: 15px;
		color: #7b7b7b;
		flex: 1;
	}
	
	.probuy_right {
		margin-right: 10px;
	}
	
	.probuy_right input {
		width: 50px;
		text-align: center;
		height: 19px;
		background: #f6fafe;
		margin-left: -4px;
		margin-right: -5px;
		border: none;
		border-top: 1px solid #26a1ff;
		border-bottom: 1px solid #26a1ff;
		border-radius: 0;
	}
	
	.btn {
		width: 21px;
		height: 21px;
		border: 1px solid #26a1ff;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		background: #26a1ff;
		color: #FFFFFF;
	}
	
	.btn1 {
		width: 21px;
		height: 21px;
		border: 1px solid #26a1ff;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		background: #26a1ff;
		color: #FFFFFF;
	}
	
	.footer {
		height: 48px;
		line-height: 48px;
		width: 100%;
		position: fixed;
		bottom: 0;
		z-index: 10;
		background: #f6fafe;
		border-top: 1px solid #c7c7c7;
		display: flex;
		font-size: 18px;
		color: #FFFFFF;
	}
	
	.kf {
		width: 49px;
	}
	
	.addcart {
		flex: 1;
		text-align: center;
		background: #ffc7ee;
	}
	
	.buy {
		flex: 1;
		text-align: center;
		background: #26A1FF;
	}
	
	.viewtype {
		width: 40%;
		height: 100%;
		float: left;
		margin-left: 5%;
		border-bottom: 2px solid #f6fafe;
		box-sizing: border-box;
		font-size: 18px;
		text-align: center;
	}
	
	.viewckd {
		border-bottom: 2px solid #26a1ff;
	}
</style>